.notification-container {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .heading {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .notification-list {
    list-style-type: none;
    padding: 0;
  }
  
  .notification-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .notification-item:hover {
    background-color: #f1f1f1;
  }
  
  .notification-content {
    display: flex;
    flex-direction: column;
  }
  
  .username {
    font-weight: bold;
    color: #007bff;
    margin-right: 5px;
  }
  
  .userid {
    color: #555;
  }
  
  .message {
    margin-top: 5px;
    font-size: 16px;
    color: #333;
  }
  
  .date {
    margin-top: 5px;
    font-size: 14px;
    color: #888;
  }
  
  .accept-button {
    padding: 8px 16px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .accept-button:hover {
    background-color: #218838;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .notification-content {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .notification-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .accept-button {
      margin-top: 10px;
    }
  }
  