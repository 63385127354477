.sidebar .nav {
  padding: 0px;
}
.sidebar ul li:hover {
  background-color: var(--active-menu);
}

.radicusstyle {
  border-radius: 10px;
  background-color: #14a814;
}
.fontfamily {
  font-size: 13px;
  font-weight: 500;
}
.fontfamilylog {
  font-size: 10px;
  font-weight: 700;
  margin-left: 5px;
  color: #7422e7;
}
.radicusstylemargin {
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
.mainscreen {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.containercenter {
  align-items: center;
}
.searchinputmain {
  display: "flex";
  align-items: "center";
}
.searchicon {
  z-index: 1;
  position: "absolute";
  margin-left: "10px";
}
.graph {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 580px) {
  .graphleftspacing {
    display: flex;
    flex-direction: column;
  }
}

.dotcolor {
  border: 19px solid red;
  border-radius: "50px";
  height: "120px";
  width: "120px";
  color: red;
}
.yearreport {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 70px;
  //background-color: aquamarine;
  align-items: center;

  justify-content: center;
}
.titleofgraph {
  display: flex;
  color: #858a85;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 40px;
}
.graphTextside {
  flex-direction: "row";
  display: flex;
  align-items: center;

  justify-items: center;
}
.graphTextsideDot::before {
  content: "";
  display: flex;
  width: 8px; /* Adjust the size of the dot */
  height: 8px;
  align-items: center;
  background-color: #ff5733; /* Adjust the color of the dot */
  border-radius: 50%; /* Make it a circle */
  margin-right: 5px;
  margin-bottom: 10px; /* Adjust the distance between the dot and text */
}
.graphTextside h6 {
  display: flex;
  align-items: center;
  color: black;
  font-size: 12px;
  font-weight: 400;
}
.graphTextsideDotapplied::before {
  content: "";
  display: flex;
  width: 8px; /* Adjust the size of the dot */
  height: 8px;
  align-items: center;
  background-color: #33ffec; /* Adjust the color of the dot */
  border-radius: 50%; /* Make it a circle */
  margin-right: 5px;
  margin-bottom: 10px; /* Adjust the distance between the dot and text */
}

.graphTextsideDotavailed::before {
  content: "";
  display: flex;
  width: 8px; /* Adjust the size of the dot */
  height: 8px;
  align-items: center;
  background-color: #33ff33; /* Adjust the color of the dot */
  border-radius: 50%; /* Make it a circle */
  margin-right: 5px;
  margin-bottom: 10px; /* Adjust the distance between the dot and text */
}

.graphTextsideDotReject::before {
  content: "";
  display: flex;
  width: 8px; /* Adjust the size of the dot */
  height: 8px;
  align-items: center;
  background-color: #ff3333; /* Adjust the color of the dot */
  border-radius: 50%; /* Make it a circle */
  margin-right: 5px;
  margin-bottom: 10px; /* Adjust the distance between the dot and text */
}
.graphTextsideDotHalf::before {
  content: "";
  display: flex;
  width: 8px; /* Adjust the size of the dot */
  height: 8px;
  align-items: center;
  background-color: #094a62; /* Adjust the color of the dot */
  border-radius: 50%; /* Make it a circle */
  margin-right: 5px;
  margin-bottom: 10px; /* Adjust the distance between the dot and text */
}

.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: Adds ellipsis (...) for overflowed text */
}

.menu-wrapper {
  position: relative;
  height: calc(100vh - 150px);
  ::-webkit-scrollbar {
    display: none;
  }
  .menus {
    max-height: 75vh;
    overflow-y: auto;
    padding: 20px;
    ::-webkit-scrollbar {
      display: none;
    }
    .menu {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #6c757d;
      cursor: pointer;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.active-menu {
  background-color:#7AB730;
  color: #000 !important;
}

.logout {
  color: #7422e7;
  position: absolute;
}
