.myprofile {
  background-color:#8b8c89 !important;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    th{
      white-space: nowrap;
    }
    button{
      display: flex;
      width: 100%;
      margin-top: 20px;
      background-color: #2d299e;
      // background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
      border-radius: 40px;
      height: 45px;
      align-items: center;
      justify-content: center;
      border: none;
      color: white;
  }

    .btn-success{
      
      border-radius: 10px;
    width: 100%;
 padding-top: 5px;
    text-align: center;
    white-space: nowrap;
    padding-bottom: 5px;
  text-transform: uppercase;
    // box-shadow: 0px 4px 35px 10px #00000040;
    }
  .btn-danger{
      
    border-radius: 10px;
  width: 100%;
padding-top: 5px;
  text-align: center;
  white-space: nowrap;
  padding-bottom: 5px;
text-transform: uppercase;
  // box-shadow: 0px 4px 35px 10px #00000040;
  }
    h4 {
      padding: 5px 10px;
      box-shadow: 0px 4px 35px 10px #00000040;
      border-radius: 15px;
    }
  
    .select {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: 20px;
  
      select {
        padding: 5px 10px;
        box-shadow: 0px 4px 35px 10px #f8f6f640;
        border-radius: 15px;
        background: rgb(204, 203, 203);
        color: rgb(11, 11, 11);
        border: none;
        width: 170px;
  
        option {
          color: black;
        }
      }
    }
    .progress-container {
      width: 100%;
      max-width: 800px;
      margin: 20px auto; // Center the progress bar and add some margin
    }
    
    .custom-progress-bar {
      border-radius: 10px;
    }
    
  
    .table-responsive {
      overflow-x: auto;
      box-shadow: 0px 4px 35px 0px #00000040;
      max-width: 800px;
      border-radius: 10px;

      .table {
        background: none;
        box-shadow: none !important;
        thead {
          tr {
            background: none;
            th {
              background: none;
              color: white;
            }
           
          }
        }
  
        tbody {
          tr {
            background: none;
            #back{
              background-color: transparent;
              padding: unset;
              text-decoration: none;
              width: 100%;
              height: 100%;
            }
            th,
            td {
              background: none;
              color: white;
              white-space: nowrap !important;
  
              span {
                display: flex;
                box-shadow: 10px 4px 35px 10px #00000040;
                height: 20px;
                width: 60px;
                font-size: 15px;
                padding: 5px 10px;
                border-radius: 5px;
                align-items: center;
                justify-content: center;
                color: white;
                background: linear-gradient(180deg, rgba(57, 30, 30, 0.2) 0%, #1e50cf 100%);
              }
            }
  
            &:last-child {
              border: none;
            }
            &:hover{
                th,td{
                    color: black;
                }
            }
          }
        }
      }
    }
  }
  


  .timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem
}

@media (min-width:768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
        content: "";
        display: block;
        border-top: .25rem dotted #3b82f6;
        width: 3.46rem;
        position: absolute;
        left: 7.5rem;
        top: .8125rem
    }
    .timeline-steps .timeline-step:not(:first-child):before {
        content: "";
        display: block;
        border-top: .25rem dotted #3b82f6;
        width: 3.8125rem;
        position: absolute;
        right: 7.5rem;
        top: .8125rem
    }
}

.timeline-steps .timeline-content {
    width: 10rem;
    text-align: center
}

.timeline-steps .timeline-content .inner-circle {
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #3b82f6
}

.timeline-steps .timeline-content .inner-circle:before {
    content: "";
    background-color: #3b82f6;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    border-radius: 6.25rem;
    opacity: .5
}