.editor{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    .image-holders{
        width: 250px;
        height: 250px;
        background-color: aqua;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .edit-inputs{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .inp{
            display: flex;
            flex-direction: column;
            input{
                padding: 5px;
                border-radius: 5px;
            }
        }
        button{
            padding: 5px;
            border-radius: 5px;
        }
    }
}
@media(max-width:863px){
    .editor{
        flex-direction: column;
    }
}

.green{
    padding: 5px;
    border-radius: 5px; 
    background-color: green;
    border: none;
}
.normal{
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(244, 22, 22); 
    border: none;

}