.pro{
    background: linear-gradient(190deg, rgba(159,32,184,1) 0%, rgb(88, 27, 106) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    .pro-image{
        display: flex;
        gap: 20px;
        
        .image-holder{
            width:120px;
            height: 120px;
            border-radius: 50%;
            img{
                width: 100%;
                height: 100%;
                border-radius: 25%;
                object-fit: cover;
            }
        }
    } 
    form{
        margin: 20px ;
        width: 700px;
        background: #fff;
        border-radius: 5px;
        padding: 20px 40px;
        box-shadow: 0px 4px 35px 0px #00000040;
        color: rgb(0, 0, 0);
        .form-control{
            background: none;
        }
        button{
            display: flex;
            width: 100%;
            margin-top: 20px;
            background-color: #2d299e;
            // background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
            border-radius: 40px;
            height: 45px;
            align-items: center;
            justify-content: center;
            border: none;
            color: white;
        }
    }
}