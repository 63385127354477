.status-box{
    display: flex;
    align-items: center;
    gap: 20px;
    .status-active{
        display: flex;
        flex-direction: column;
        background-color: #5c5c90;
        padding: 20px;
        height: 100px;
        width: 250px;
        border-radius: 10px;
    }
    .status-inactive{
        display: flex;
        flex-direction: column;
        background-color: #f59357;
        padding: 20px;
        height: 100px;
        width: 250px;
        border-radius: 10px;

    }
    .income-generated{
        display: flex;
        flex-direction: column;
        background-color: #93d1ee;
        padding: 20px;
        height: 100px;
        width: 250px;
        border-radius: 10px;

    }
}
 .status-button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #93d1ee;
  }
.filter-box{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;
    select{
        width: 100px;
        height: 30px;
        border-radius: 5px;
        background-color: #f59357;
    }
}
@media(max-width:500px){
    .status-box{
        gap: 10px;
        .status-active{
            padding: 10px;
            height: 70px;
            width: 150px;
            border-radius: 10px;
            h6{
                font-size: 14px;
            }
            h5{
                font-size: 12px;
            }
        }
        .status-inactive{
            padding: 10px;
            height: 70px;
            width: 150px;
            border-radius: 10px;
            h6{
                font-size: 14px;
            }
            h5{
                font-size: 12px;
            }
        }
        .income-generated{
            padding: 10px;
            height: 70px;
            width: 150px;
            border-radius: 10px;
            h6{
                font-size: 14px;
            }
            h5{
                font-size: 12px;
            }
        }
    }
}