/* Main Container Styling */
.profilemain {
    max-width: 350px;
    margin: 50px auto;
    padding: 0px;
    text-align: center;
}

/* Profile Card Styling */
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F2F4F8; /* Light Gray Background */
    padding: 25px;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Stronger Box Shadow */
    max-width: 350px;
    margin: 20px auto;
    color: #333; /* Dark Gray Text */
}

/* Profile Image Styling */
.profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 5px solid #1A3C6C; /* Navy Blue Border */
    margin-bottom: 20px;
    object-fit: cover;

}

/* Welcome Text Styling */
.profile-welcome {
    font-size: 28px;
    font-weight: bold;
    color: #1A3C6C; /* Navy Blue Text */
    margin-bottom: 20px;
}

/* Profile Name Styling */
.profile-name {
    font-size: 24px;
    font-weight: bold;
    color: #C29D49; /* Gold Text */
    margin-bottom: 10px;
}

/* Referral ID Styling */
.referral-id {
    font-size: 18px;
    color: #C29D49; /* Gold Text */
    background-color: #E1E5EB; /* Light Gray Background */
    padding: 8px 15px;
    border-radius: 12px;
    margin-bottom: 15px;
}

/* Stats Text Styling */
.profile-stats {
    font-size: 20px;
    color: #1A3C6C; /* Navy Blue Text */
    margin-bottom: 10px;
}

.profile-stattotal{
    font-size: 20px;
    color: #1A3C6C; /* Navy Blue Text */
    margin-bottom: 10px;
    font-weight: bold;

}